import { get, post } from '@/helpers/HttpHelper'
import qs from 'qs'

export class ProductoService {
  async getProductos (params) {
    const queryString = qs.stringify(params, { arrayFormat: 'brackets' })
    const response = await get(`/productos?${queryString}`)
    return response.data
  }

  async getSearchProductos (params) {
    const queryString = qs.stringify(params)
    const response = await get(`/buscar_productos?${queryString}`)
    return response.data.data
  }

  async getDetalleProducto (productoId) {
    const response = await get(`detalle_producto?codigo=${productoId}`)
    return response.data.data
  }

  async getProductosMasVendido () {
    const response = await get('/productos_mas_vendidos')
    return response.data.data
  }

  async getProductosSimilares (formData) {
    const response = await post('/productos_similares', formData)
    return response.data.data
  }

  async getProductosComplementarios (formData) {
    const response = await post('/productos_complementarios', formData)
    return response.data.data
  }

  async cargarImagenes (formData) {
    const response = await post('/admin/productos/cargar_imagenes', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data
  }

  async getTransportistas () {
    const response = await get('/transportistas')
    return response.data.data
  }

  async getClienteDirecciones () {
    const response = await get('/cliente/direcciones')
    return response.data.data
  }

  async generarOrden (dataOrden) {
    const response = await post('/productos/generar_orden', dataOrden)
    return response.data
  }
}
