<template>
  <header class="header">
    <div class="top-header py-3">
      <div class="container-lg">
        <div class="row text-center">
          <div class="register-text col-auto text-uppercase text-white montserrat-semibold">
            <span class="d-none d-md-block">Si eres cliente nuevo, puedes registrarte <router-link to="/registrarse"
                class="text-white text-decoration-underline">aquí</router-link></span>
            <span class="d-md-none">¿Cliente nuevo? regístrate <router-link to="/registrarse"
                class="text-white text-decoration-underline">aquí</router-link></span>
          </div>
          <div class="links col-auto ms-auto text-uppercase montserrat-semibold d-none d-md-block">
            <ul class="list-inline mb-0 d-flex">
              <li class="list-inline-item"><a href="#" class="text-white text-decoration-none">Nexus Corporativo</a>
              </li>
              <li class="list-inline-item"><a href="#" class="text-white text-decoration-none">Nexus Academy</a></li>
              <li class="list-inline-item"><a href="#" class="text-white text-decoration-none">Contáctanos</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="main-header py-3 py-md-4 pb-0">
      <div class="main-header__content container-lg d-flex align-items-center justify-content-between">
        <div class="logo order-1">
          <router-link to="/" class="w-100"><img src="@/assets/catalogo/logo.svg" alt="Logo" /></router-link>
        </div>
        <div class="search-container search order-xs-3 order-md-2" v-click-outside="closeListbox">
          <form class="search-form d-flex align-items-center border rounded-pill overflow-hidden" @submit.prevent>
            <InputText
              v-model="searchQuery"
              @input="handleInput"
              placeholder="Buscar productos, marcas y más"
              class="search__input form-control border-0 py-2"
            />
            <label for="searchInput" class="search__icon"
                ><i class="bi bi-search"></i
              ></label>
          </form>
          <div v-if="searchResults.length" class="search-results">
            <Listbox
              v-model="selectedItem"
              :options="searchResults"
              optionLabel="nombre"
              @change="selectResult()"
              class="w-100"
            >
              <template #option="slotProps">
                <div class="listbox-item d-flex align-items-center p-2">
                  <img :src="slotProps.option.fotos.principal" alt="Product Image" class="product-image" />
                  <div class="product-info">
                    <div class="product-name">{{ slotProps.option.nombre }}</div>
                    <div class="product-description">{{ slotProps.option.codigo }}</div>
                    <div class="product-price"></div>
                  </div>
                </div>
              </template>
            </Listbox>
          </div>
        </div>
        <div class="user-menu montserrat-semibold text-uppercase order-2 order-md-3">
          <ul class="list-inline mb-0 d-flex align-items-center">
            <li class="order-3 order-md-1">
              <router-link to="/login" class="text-decoration-none d-flex align-items-center"><i
                  class="bi bi-person"></i> <span class="d-none d-xl-block">Mi cuenta</span></router-link>
            </li>
            <li class="order-2">
              <button class="link text-decoration-none d-flex align-items-center montserrat-semibold text-uppercase"
                type="button" id="userMenuCart" @click="showCarrito = true">
                <i class="bi bi-cart2"></i>
                <span class="d-none d-xl-block">Mi carro</span>
                <span class="extra">({{ productos.length }})</span>
              </button>
            </li>
            <li class="d-flex align-items-center order-1 order-md-3">
              <span class="d-none d-xl-block">Compra en</span>
              <div class="currencies d-flex align-items-center rounded-pill ms-2">
                <a @click="cambiarMoneda('ext')" class="cursor-pointer"
                  :class="['py-1 text-center rounded-pill inter-bold text-decoration-none', { 'is-active': selectedCurrency === 'ext' }]">
                  $
                </a>
                <a @click="cambiarMoneda('local')" class="cursor-pointer"
                  :class="['py-1 text-center rounded-pill inter-bold text-decoration-none', { 'is-active': selectedCurrency === 'local' }]">
                  S/.
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div class="minicart rounded" id="miniCart" v-if="showCarrito && isLoggedIn">
          <div class="text-end mb-3">
            <button class="button-close" type="button" id="miniCartClose" @click="showCarrito = false">
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
          <h3 class="title montserrat-semibold">Bolsa de compra ({{ productos.length }})</h3>
          <div class="items d-flex flex-column">
            <div class="item d-flex gap-3" v-for="(producto,index) in productos" :key="index">
              <div class="image">
                <img :src="producto.producto.fotos.principal" alt="" />
              </div>
              <div class="description d-flex flex-column justify-content-between">
                <span class="name montserrat-medium">{{ producto.producto.descripcion }}</span>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="price montserrat-bold" v-if="selectedCurrency == 'local'">S/ {{
                    producto.producto.precio_local_sin_igv }}</span>
                  <span class="price montserrat-bold" v-if="selectedCurrency == 'ext'">$ {{
                    producto.producto.precio_ext_sin_igv }}</span>
                  <a @click="quitarProductoCarrito(producto)" class="button-remove"><i class="bi bi-trash3"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons text-center">
            <router-link to="/carrito" @click="showCarrito = false" class="btn montserrat-semibold rounded-pill">Ver bolsa / pagar</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-header py-3 d-none d-md-block">
      <div class="container-lg">
        <div class="row">
          <div class="links col-auto ms-auto text-uppercase montserrat-semibold">
            <ul class="list-inline mb-0 d-flex gap-4">
              <li class="list-inline-item">
                <router-link to="/catalogo?chkPromo=1"
                  class="montserrat-semibold text-uppercase text-decoration-none">Promociones</router-link>
              </li>
              <li class="list-inline-item">
                <router-link to="/catalogo"
                  class="link-catalog montserrat-semibold text-uppercase text-decoration-none rounded-pill py-2 px-5 text-white">Ver
                  catálogo</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ProductoService } from '@/services/ProductoService.js'
export default {
  data () {
    return {
      searchQuery: '',
      searchResults: [],
      productoService: new ProductoService(),
      showCarrito: false
    }
  },
  computed: {
    ...mapState({
      selectedCurrency: state => state.carrito.selectedCurrency,
      productos: state => state.carrito.productos,
      isLoggedIn: state => state.user.isLoggedIn
    })
  },
  methods: {
    handleInput () {
      if (this.searchQuery.length >= 3) {
        this.searchProducts()
      } else {
        this.searchResults = []
      }
    },
    async searchProducts () {
      const paramsPromociones = {
        search: this.searchQuery,
        limit: 5
      }
      this.productoService.getSearchProductos(paramsPromociones).then((data) => {
        this.searchResults = data
      })
    },
    selectResult () {
      this.$router.push({ path: '/producto', query: { codigo: this.selectedItem.codigo } })
    },
    ...mapActions({
      setCurrency: 'carrito/cambiarMoneda',
      quitarProducto: 'carrito/quitarProducto'
    }),
    cambiarMoneda (moneda) {
      this.setCurrency(moneda)
      console.log(`Moneda cambiada a: ${moneda}`)
    },
    quitarProductoCarrito (producto) {
      console.log(producto)
      this.quitarProducto(producto)
    },
    closeListbox () {
      this.searchQuery = ''
      this.searchResults = []
    }
  },
  mounted () {
    const savedCurrency = this.selectedCurrency
    if (savedCurrency) {
      this.setCurrency(savedCurrency)
    }
  },
  directives: {
    clickOutside: {
      mounted (el, binding) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event)
          }
        }
        document.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted (el) {
        document.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
}
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.header .main-header__content .minicart{
  display: block !important;
}
.minicart {
  z-index: 1000;
}
.search {
  max-width: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .search {
    max-width: 400px;
  }
}
.search-container {
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 0 15px; /* Add padding to prevent touching screen edges */
}

.search-form {
  width: 100%;
}

.search__input {
  width: 100%;
  box-sizing: border-box;
}

.search-results {
  position: absolute;
  left: 15px;
  right: 15px;
  z-index: 1000;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  margin-top: 5px;
}

.listbox-item {
  transition: background-color 0.2s;
}

.listbox-item:hover {
  background-color: #f5f5f5;
}

.product-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  flex-shrink: 0;
}

.product-info {
  flex-grow: 1;
  min-width: 0; /* Allows text to wrap instead of expanding container */
  margin-left: 10px;
}

.product-name {
  font-weight: bold;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-description {
  font-size: 0.8rem;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-price {
  font-weight: bold;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .search-container {
    max-width: 400px;
  }
}
</style>
