<template>
<footer class="footer py-4">
      <div class="container-lg">
        <div class="footer__top row" v-if="false">
          <div class="col-3 d-none d-lg-block">
            <div id="footerLinks01Desktop">
              <ul class="links d-flex flex-column gap-1 gap-lg-3" id="footerLinks01">
                <li><a href="">Atención al cliente</a></li>
                <li><a href="">Políticas</a></li>
                <li><a href="">¿Cómo comprar?</a></li>
              </ul>
            </div>
          </div>
          <div class="col-3 d-none d-lg-block">
            <div id="footerLinks02Desktop">
              <ul class="links d-flex flex-column gap-1 gap-lg-3" id="footerLinks02">
                <li><a href="">Beneficios de ser cliente</a></li>
                <li><a href="">Libro de reclamaciones</a></li>
                <li><a href="">Contáctanos</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="newsletter">
              <h3 class="title montserrat-semibold">Recibe nuestras promociones exclusivas</h3>
              <form action="" class="form d-flex align-items-center gap-4">
                <input type="text" class="form-control" placeholder="Ingrese su correo" />
                <input type="submit" value="Suscribirme" class="btn rounded-pill montserrat-semibold" />
              </form>
              <span class="message"
                >Al suscribirme acepto la <strong class="montserrat-semibold">política de protección de datos</strong></span
              >
            </div>
          </div>
        </div>
        <div class="footer__middle row d-none d-lg-flex">
          <div class="col-3">
            <div id="footerOfficeDesktop">
              <div class="office" id="footerOffice">
                <h3 class="title montserrat-semibold">Oficina</h3>
                <span class="address">Av. Ricardo Palma 693, Miraflores</span>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div id="footerStoreDesktop">
              <div class="store" id="footerStore">
                <h3 class="title montserrat-semibold">Almacén</h3>
                <span class="address">Av. República de Ecuador 557, Cercado</span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="whatsapp">
              <h3 class="title montserrat-semibold">Whatsapp</h3>
              <span class="phone montserrat-bold">+51 963 749 700</span>
            </div>
          </div>
        </div>
        <div class="footer__bottom row d-none d-lg-flex ">
          <div class="col-4">
            <div id="footerCardsDesktop">
              <div class="cards" id="footerCards">
                <img src="@/assets/catalogo/img/cards-logo.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-6 offset-2">
            <div id="footerSocialNetworksDesktop">
              <div class="social-networks d-flex gap-4 justify-content-end flex-lg-row align-items-lg-center" id="footerSocialNetworks">
                <div class="row">
                  <div class="col-9">
                    <span class="text">Síguenos en todas nuestras plataformas digitales</span>
                  </div>
                  <div class="col-3">
                    <ul class="list d-flex gap-2">
                      <li>
                        <a href="https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F65261661" target="_blank"><img src="@/assets/catalogo/img/linkedin-logo.svg" alt="LinkedIn" /></a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/Nexus.Technology.SAC/" target="_blank"><img src="@/assets/catalogo/img/facebook-logo.svg" alt="Facebook" /></a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/@nexustechnology3680" target="_blank"><img src="@/assets/catalogo/img/youtube-logo.svg" alt="YouTube" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="footer-mobile d-lg-none">
          <Accordion expandIcon="bi bi-plus" collapseIcon="bi bi-dash" :multiple="true">
            <AccordionTab class="accordion-tab">
              <template #header>
                <span class="flex align-items-center gap-2 w-full">
                  <span class="montserrat-bold accordion-button-footer white-space-nowrap">Dirección</span>
                </span>
              </template>
              <div id="footerOfficeDesktop">
                <div class="office" id="footerOffice">
                  <h3 class="title montserrat-semibold">Oficina</h3>
                  <span class="address">Av. Ricardo Palma 693, Miraflores</span>
                </div>
              </div>
              <div id="footerStoreDesktop">
                <div class="store" id="footerStore">
                  <h3 class="title montserrat-semibold">Almacén</h3>
                  <span class="address">Av. República de Ecuador 557, Cercado</span>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab class="accordion-tab">
              <template #header>
                <span class="flex align-items-center gap-2 w-full">
                  <span class="montserrat-bold accordion-button-footer white-space-nowrap">Atención al cliente</span>
                </span>
              </template>
              <div id="footerLinks01Desktop">
                <ul class="links d-flex flex-column gap-1 gap-lg-3" id="footerLinks01">
                  <li><a href="">Atención al cliente</a></li>
                  <li><a href="">Políticas</a></li>
                  <li><a href="">¿Cómo comprar?</a></li>
                  <li><a href="">Beneficios de ser cliente</a></li>
                  <li><a href="">Libro de reclamaciones</a></li>
                  <li><a href="">Contáctanos</a></li>
                </ul>
              </div>
            </AccordionTab>
            <AccordionTab class="accordion-tab">
              <template #header>
                <span class="flex align-items-center gap-2 w-full">
                  <span class="montserrat-bold accordion-button-footer white-space-nowrap">Métodos de pago</span>
                </span>
              </template>
              <div id="footerCardsDesktop">
                <div class="cards" id="footerCards">
                  <img src="@/assets/catalogo/img/cards-logo.png" alt="" />
                </div>
              </div>
            </AccordionTab>
          </Accordion>
          <div id="footerSocialNetworksMobile">
            <div class="social-networks d-flex gap-4 flex-column flex-lg-row" id="footerSocialNetworks">
              <div class="row">
                <div class="col-10">
                  <span class="text">Síguenos en todas nuestras plataformas digitales</span>
                </div>
                <div class="col-12">
                  <ul class="list d-flex gap-2">
                    <li>
                      <a href="https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F65261661" target="_blank"><img src="@/assets/catalogo/img/linkedin-logo.svg" alt="LinkedIn" /></a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/Nexus.Technology.SAC/" target="_blank"><img src="@/assets/catalogo/img/facebook-logo.svg" alt="Facebook" /></a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@nexustechnology3680" target="_blank"><img src="@/assets/catalogo/img/youtube-logo.svg" alt="YouTube" /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a :href="`https://api.whatsapp.com/send?phone=${numberphone}&text=${encodeURI(message)}`" target="_blank" class="whatsapp-link text-white d-flex align-items-center justify-content-center rounded-circle"
        ><i class="bi bi-whatsapp"></i
      ></a>
    </footer>
</template>

<script>
export default {
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  },
  data () {
    return {
      numberphone: '51963749700',
      message: 'Hola!'
    }
  }
}
</script>
<style scoped>
.footer-mobile .accordion-button-footer {
  background-color: #fff;
  border: 0;
  border-radius: 8px !important;
  box-shadow: none;
  color: #1d3a6e;
}
.footer-mobile .p-accordion .p-accordion-tab {
  margin-bottom: 15px !important;
}
.footer-mobile .p-accordion .p-accordion-tab a{
  text-decoration: none;
}
</style>
