import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'primeflex/primeflex.css'
import 'primevue/resources/themes/aura-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import '@/assets/css/style.css'
import '@/assets/catalogo/styles.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import PrimeVue from 'primevue/config'
import StyleClass from 'primevue/styleclass'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Tag from 'primevue/tag'
import InputText from 'primevue/inputtext'
import Dialog from 'primevue/dialog'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import FileUpload from 'primevue/fileupload'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Preloader from '@/components/preloader.vue'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import Dropdown from 'primevue/dropdown'
import Steps from 'primevue/steps'
import Listbox from 'primevue/listbox'

library.add(fas, fab)
const app = createApp(App)

app.directive('styleclass', StyleClass)

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('PButton', Button)
app.component('Tag', Tag)
app.component('InputText', InputText)
app.component('PDialog', Dialog)
app.component('IconField', IconField)
app.component('InputIcon', InputIcon)
app.component('FileUpload', FileUpload)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('Toast', Toast)
app.component('Dropdown', Dropdown)
app.component('Steps', Steps)
app.component('Listbox', Listbox)

app.component('Preloader', Preloader)

app.use(PrimeVue)
app.use(ToastService)
app.use(store)
app.use(router)

app.mount('#app')
