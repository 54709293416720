import { createRouter, createWebHistory } from 'vue-router'
import layoutCatalogo from '@/layoutCatalogo.vue'
import layoutAdmin from '@/layoutAdmin.vue'
import { isSessionValid, hasPermissionGuard } from '@/guards/sessionGuard.js'
import store from '@/store/store.js'

const routes = [
  {
    path: '/login',
    component: layoutCatalogo,
    children: [
      { path: '', component: () => import('@/layoutLogin.vue'), name: 'catalogoLogin' }
    ]
  },
  {
    path: '/registrarse',
    component: layoutCatalogo,
    children: [
      { path: '', component: () => import('@/registrarse.vue'), name: 'catalogoRegister' }
    ]
  },
  {
    path: '/recuperarClave',
    component: layoutCatalogo,
    children: [
      { path: '', component: () => import('@/recuperarClave.vue'), name: 'catalogoRecuperarClave' }
    ]
  },
  {
    path: '/',
    component: layoutCatalogo,
    meta: { requiresAuth: false },
    children: [
      { path: '', component: () => import('@/views/catalogo/home.vue'), name: 'home' },
      { path: 'catalogo', component: () => import('@/views/catalogo/catalogo.vue'), name: 'catalogo' },
      { path: 'producto', component: () => import('@/views/catalogo/producto.vue'), name: 'producto' },
      { path: 'carrito', component: () => import('@/views/catalogo/carrito/carrito.vue'), name: 'carrito' },
      { path: 'estadoPago', component: () => import('@/views/catalogo/carrito/estadoPago.vue'), name: 'estadoPago' }
    ]
  },
  {
    path: '/admin',
    component: layoutAdmin,
    meta: { requiresAuth: true, roles: [1] },
    children: [
      { path: '', redirect: { name: 'productos' } },
      { path: '/admin/productos', component: () => import('@/views/admin/productos/productos.vue'), name: 'productos' },
      { path: '/admin/usuarios', component: () => import('@/views/admin/usuarios/usuarios.vue'), name: 'usuarios' }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const rutasCatalogo = ['home', 'catalogo', 'producto', 'carrito']
  if (rutasCatalogo.includes(to.name)) {
    const { valid, message } = await isSessionValid()
    if (!valid && message) {
      await store.dispatch('user/logout')
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const validSession = await isSessionValid()
    if (!validSession) {
      next({ path: '/login' })
    } else {
      const allowedRoles = to.matched.flatMap(record => record.meta.roles ? record.meta.roles : [])
      const userRole = store.state.user.data.usuario_rol
      const hasPermission = userRole.some(role => allowedRoles.includes(role))
      if (hasPermission) {
        next()
      } else {
        window.location.href = '/'
      }
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!hasPermissionGuard()) {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
