export default ({
  namespaced: true,
  state: {
    data: {},
    isLoggedIn: false,
    popUp: true
  },
  mutations: {
    setUserData (state, userData) {
      state.data = userData
      state.isLoggedIn = true
    },
    setLoggedIn (state, status) {
      state.isLoggedIn = status
    },
    setUserPopUp (state, status) {
      state.popUp = status
    },
    clearUserData (state) {
      state.data = {}
      state.isLoggedIn = false
    }
  },
  actions: {
    setUserData ({ commit }, userData) {
      commit('setUserData', userData)
    },
    setUserPopUp ({ commit }, status) {
      commit('setUserPopUp', status)
    },
    checkSession ({ commit, state }) {
      const token = state.data.token
      const expirationDate = state.data.expirationDate

      if (token && expirationDate) {
        const currentTime = new Date().getTime()
        const expirationTime = new Date(expirationDate).getTime()
        if (currentTime >= expirationTime) {
          commit('clearUserData')
        }
      } else {
        commit('clearUserData')
      }
    },
    logout ({ commit }) {
      commit('clearUserData')
    }
  }
})
